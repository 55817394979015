<template>
  <div class="container">
    <content-title :nav='nav'></content-title>
    <all-map></all-map>
  </div>
</template>

<script>
export default {
  data(){
    return {
      nav:{firstNav:'机构管理中心',secondNav:"机构地图"}
    }
  }
}
</script>

<style>

</style>